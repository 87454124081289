<template>
  <section class="elv-treasury-balance-verification-container">
    <header class="elv-treasury-balance-verification-header">
      <div class="elv-treasury-balance-verification-header__title" @click="handleBackClick">
        <SvgIcon name="arrow-left" width="18" height="18" />
        <p>{{ transformI18n(backButtonText) }}</p>
      </div>
    </header>
    <router-view></router-view>
  </section>
</template>

<script setup lang="ts">
import { $t, transformI18n } from '@/i18n/index'

const route = useRoute()
const router = useRouter()

const backButtonText = computed(() => {
  let text = ''
  switch (route?.name) {
    case 'entity-accounts-balance-verify-tasks':
      text = $t('button.backToTreasury')
      break
    case 'entity-accounts-balance-verify-task-detail':
      text = $t('button.backToBalanceVerifyTasks')
      break
    default:
      break
  }
  return text
})

const handleBackClick = () => {
  if (route?.name === 'entity-accounts-balance-verify-tasks') {
    router.push({ name: 'entity-accounts-treasury' })
  } else if (route?.name === 'entity-accounts-balance-verify-task-detail') {
    const pageNum = window?.history?.state?.page || 1
    router.push({ name: 'entity-accounts-balance-verify-tasks', query: { type: route?.query?.type, page: pageNum } })
  }
}
</script>

<style lang="scss" scoped>
.elv-treasury-balance-verification-container {
  height: 100%;
  position: relative;
}

.elv-treasury-balance-verification-header {
  box-sizing: border-box;
  padding: 16px 20px 16px 17px;

  .elv-treasury-balance-verification-header__title {
    display: flex;
    align-items: center;
    width: fit-content;

    svg {
      fill: #838d95;
      transition: fill 0.1s;
    }

    p {
      margin-left: 8px;
      padding-left: 8px;
      color: #000;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 18px;
        background-color: #edf0f3;
      }
    }

    &:hover {
      cursor: pointer;

      svg {
        fill: #1e2024;
      }
    }
  }
}
</style>
